import { template as template_a2e95237492c4368bb37a919d4ced5dc } from "@ember/template-compiler";
const WelcomeHeader = template_a2e95237492c4368bb37a919d4ced5dc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
