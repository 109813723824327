import { template as template_0a964496403d4d06a4a66ce6ed230d28 } from "@ember/template-compiler";
const FKLabel = template_0a964496403d4d06a4a66ce6ed230d28(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
