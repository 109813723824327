import { template as template_db1d834bae7c497ea2e684730d9801a4 } from "@ember/template-compiler";
const FKText = template_db1d834bae7c497ea2e684730d9801a4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
