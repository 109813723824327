import { template as template_f58b1b58b48c448fae7d077ebbcfaa52 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f58b1b58b48c448fae7d077ebbcfaa52(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
